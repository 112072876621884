import { onLoad } from './libs/helpers';
function $<T = HTMLElement>(id: string): T {
  return document.getElementById(id) as T;
}

const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
onLoad(() => {
  // Scroll into view
  const elmnt = document.querySelector('.this-page');
  if (elmnt) {
    elmnt.scrollIntoView({ block: 'center' });
  }
});

window['openMobileMenu'] = () => {
  var x = document.getElementById('mobileMenu');
  var y = document.getElementById('basicsMenu');
  var z = document.getElementById('passMenu');
  var a = document.getElementById('placeMenu');
  var b = document.getElementById('spreadMenu');
  var c = document.getElementById('propMenu');
  var d = document.getElementById('comeMenu');
  var e = document.getElementById('dontMenu');
  if (x.className.indexOf('w3-show') == -1) {
    x.className += ' w3-show';
  } else {
    x.className = x.className.replace(' w3-show', '');
    y.className = y.className.replace(' w3-show', '');
    z.className = z.className.replace(' w3-show', '');
    a.className = a.className.replace(' w3-show', '');
    b.className = b.className.replace(' w3-show', '');
    c.className = c.className.replace(' w3-show', '');
    d.className = d.className.replace(' w3-show', '');
    e.className = e.className.replace(' w3-show', '');
  }
};

window['openMenu'] = menuId => {
  var x = document.getElementById(menuId);
  if (x.className.indexOf('w3-show') == -1) {
    x.className += ' w3-show';
  } else {
    x.className = x.className.replace(' w3-show', '');
  }
};

const classHidden = 'w3-hide';
window['openNav'] = () => {
  var x = document.getElementById('close');
  var y = document.getElementById('open');
  var a = document.getElementById('mobileNav');
  a.style.width = '280px';
  if (x.classList.contains(classHidden)) {
    x.classList.remove(classHidden);
    y.classList.add(classHidden);
  }
};

window['closeNav'] = () => {
  var x = document.getElementById('close');
  var y = document.getElementById('open');
  var z = document.getElementById('home-main');
  var a = document.getElementById('basicsMenu');
  var b = document.getElementById('passMenu');
  var c = document.getElementById('placeMenu');
  var d = document.getElementById('spreadMenu');
  var e = document.getElementById('propMenu');
  var f = document.getElementById('comeMenu');
  var g = document.getElementById('dontMenu');
  var h = document.getElementById('practiceMenu');

  document.getElementById('mobileNav').style.width = '0';
  a.style.width = '0';
  b.style.width = '0';
  c.style.width = '0';
  d.style.width = '0';
  e.style.width = '0';
  f.style.width = '0';
  g.style.width = '0';
  h.style.width = '0';

  if (y.classList.contains(classHidden)) {
    y.classList.remove(classHidden);
    x.classList.add(classHidden);
    y.style.fontSize = '24px';
    y.style.top = '37px';
  }
};

window['openSubMenu'] = hiddenMenu => {
  var x = document.getElementById(hiddenMenu);
  x.style.width = '280px';
  x.style.top = '105px';
};

window['closeSubMenu'] = openedMenu => {
  var x = document.getElementById(openedMenu);
  x.style.width = '0';
};

window['openTab'] = (tabName, tabContent, tabLinks) => {
  var i, tabcontent, tablinks;

  tabcontent = document.getElementsByClassName(tabContent);
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = 'none';
  }
  Array.from(document.getElementsByClassName(tabLinks)).forEach(tabLink => {
    tabLink.classList.remove('open-tab');
    tabLink.classList.add('closed-tab');
  });
  const newActiveTab = document.getElementById(tabName);
  newActiveTab.style.display = 'block';
  (event.currentTarget as HTMLElement).classList.remove('closed-tab');
  (event.currentTarget as HTMLElement).classList.add('open-tab');
};

window['changeBackground'] = (id: string, dmBgColor: string, dmColor: string, lmBgColor: string, lmColor: string) => {
  const el = document.getElementById(id);
  if (el) {
    if (darkMode) {
      el.style.backgroundColor = dmBgColor;
      el.style.color = dmColor;
    } else {
      el.style.backgroundColor = lmBgColor;
      el.style.color = lmColor;
    }
  }
  var elementRect = el.getBoundingClientRect();
  var absoluteElementTop = elementRect.top + window.pageYOffset;
  var middleOfViewport = document.getElementById('sidenav').clientHeight / 2;
  var scrollToPosition = absoluteElementTop - middleOfViewport;
  
  document.getElementById('sidenav').scrollTo({
    top: scrollToPosition,
    left: 0,
    //behavior: 'smooth'
  });
}